import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do I Need The Security Analyst?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The security analyst is a great solution for analyzing and managing networked systems risks. The security analyst is an immediately deployable, accessible and efficient tool that can be easily customized and integrated. With the information security analyst, you are ready for future changes and new standards."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Which Functionalities Are Offered By The Security Analyst?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Security Analyst offered a lot of functionalities including standardization, customization, modeling, reporting and lifecycle support (simple update and versioning of analysis iterations). At Codestaff, you are sure to get the best security analysis."
        }
    }
}

const InformationSecurityAnalyst = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Information Security Analysts.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Information Security Analysts. Top
                                    companies and start-ups choose Codestaff professional Information
                                    Security Analysts for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Information Security Analyst now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE INFORMATION SECURITY ANALYSTS' banner='/information-security-analyst.png' bannerAlt='information security analyst banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default InformationSecurityAnalyst