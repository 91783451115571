import React from 'react'
import Layout from '../components/layout'
import InformationSecurityAnalyst from '../components/categories/information-security-analyst'
import Seo from '../components/seo'

const InformationSecurityAnalystPage = ({ location }) => {
  return (
    <Layout location={location}>
      <InformationSecurityAnalyst />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Information Security Analyst Freelancers | Codestaff'
  const description =
    'Hire the best Information Security Analyst freelancers at Codestaff. Get the top 1% of Information Security Analyst professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default InformationSecurityAnalystPage
